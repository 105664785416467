import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { FilterHomeComponent } from "./FilterHomeComponent";
import { useFetchCampaign } from "../hooks/useCampaign";
import { useFetchCampaignDuration } from "../hooks/useDuration";
import { useFetchCampaignAmount } from "../hooks/useAmount";

export default function CampaingPast() {
  const defaultCantidadDias = 30;

  const {
    vnHome: viewName,
    vfVerCampanasActivasSalientes: viewFunction,
    vfIrDetalleCampana,
    vnListadoCampana,
    vfVerTodasLasCampanas,
    vfVerDuracion,
  } = viewTypes;

  const showView = ShowView(viewFunction, viewName);
  const showDetalle = ShowView(vfIrDetalleCampana, vnListadoCampana);
  const showDuration = ShowView(vfVerDuracion, vnListadoCampana);

  const restarDias = (cantidadDias) => {
    const fechaActual = moment();
    const nuevaFecha = fechaActual.subtract(cantidadDias, "days");
    return nuevaFecha.format("YYYY-MM-DD");
  };

  const initParams = {
    rows: 5,
    page: 1,
    sortField: null,
    ordenColumn: null,
    nombre: "",
    estado: "4",
    fechaInicioDesde: restarDias(defaultCantidadDias),
    fechaInicioHasta: restarDias(0),
  };

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [lazyParams, setLazyParams] = useState(initParams);

  const buildFilterCampaign = (params) => ({
    userId: localStorage.getItem("user"),
    estado: ["4"],
    fechaInicioDesde: params.fechaInicioDesde,
    fechaInicioHasta: params.fechaInicioHasta,
    pagesize: params.rows,
    pagenumber: params.page,
    direction: params.ordenColumn,
    field: params.sortField,
    permitirVista: ShowView(vfVerTodasLasCampanas, vnListadoCampana),
  });

  const [filterCampaign, setFilterCampaign] = useState(
    buildFilterCampaign(initParams)
  );

  useEffect(() => {
    setFilterCampaign(buildFilterCampaign(lazyParams));
  }, [lazyParams]);

  const buildData = (responseData) => {
    const armarParsed = (arr) => {
      return arr.map((c) => {
        return {
          _id: c._id,
          nombre: corregirNombre(c.nombre),
          surveyId: c.idSurvey,
          voicebot: corregirNombre(c.surveyName),
          estado: "FINALIZADA",
          fecha: formatoFechaVista(c.fechaInicio),
          tipo: c.surveyTipo,
          tipoEncuesta: c.surveyTipo,
          casosARealizar: c.casosARealizar.toLocaleString(),
        };
      });
    };
    const _parsed = armarParsed(responseData);
    return _parsed;
  };

  const fetchCampaigns = useFetchCampaign(filterCampaign);

  useEffect(() => {
    if (fetchCampaigns.data) {
      setCustomers(buildData(fetchCampaigns.data.campaigns));
      setTotalRecords(fetchCampaigns.data.pagination.count);
    }
  }, [fetchCampaigns.data]);

  useEffect(() => {
    setLoading(fetchCampaigns.isLoading);
  }, [fetchCampaigns.isLoading]);

  /** */

  const corregirNombre = (nombre) => {
    return nombre.split("_").join(" ");
  };

  const formatoFechaVista = (fecha) => {
    let date = moment(fecha);
    return date.format("DD/MM/YYYY");
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const Duration = ({ campaignId }) => {
    const { data, error, isLoading } = useFetchCampaignDuration(campaignId);

    if (isLoading) return <i className="pi pi-spin pi-spinner"></i>;
    if (error) return <span>Error</span>;

    const duration = data ? data.durationCall : 0;
    return <span>{duration}</span>;
  };

  const Amount = ({ campaignId }) => {
    const { data, error, isLoading } = useFetchCampaignAmount(campaignId);

    if (isLoading) return <i className="pi pi-spin pi-spinner"></i>;
    if (error) return <span>Error</span>;

    const amount = data ? data : 0;
    return <span>{amount}</span>;
  };

  const redirectEncuesta = (datos) => {
    sessionStorage.setItem("campanaId", datos._id);
    sessionStorage.setItem("campanaName", datos.nombre);
    sessionStorage.setItem("surveyName", datos.voicebot);
    sessionStorage.setItem("campanaState", datos.estado);
    sessionStorage.setItem("survey", datos.surveyId);
    sessionStorage.setItem("campanaType", datos.tipoEncuesta.toLowerCase());
    sessionStorage.setItem("typeSurvey", datos.tipo);
    window.location.href = "/detalle";
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Tooltip target=".target-icon" />
        <span
          className="target-icon"
          style={{
            fontSize: "1.4rem",
            marginRight: "16px",
            color: "#2196F3",
            cursor: "pointer",
          }}
          data-pr-tooltip={rowData.tipo}
        >
          {rowData.tipo === "Saliente" ? (
            <i className="pi pi-sign-out"></i>
          ) : (
            <i className="target-icon pi pi-sign-in"></i>
          )}
        </span>
        <span>{rowData.nombre}</span>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {showDetalle && (
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => redirectEncuesta(rowData)}
            tooltip="Ir al detalle"
            tooltipOptions={{ position: "left" }}
          />
        )}
      </>
    );
  };

  const surveyBodyTemplate = (rowData) => {
    return (
      <span style={{ textAlign: "left", display: "block" }}>
        {rowData.voicebot}
      </span>
    );
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  return (
    <>
      {showView && (
        <div className="card">
          <div className="card-header">
            <h4>Campañas Finalizadas</h4>
            <FilterHomeComponent
              setLazyParams={setLazyParams}
              restarDias={restarDias}
              defaultCantidadDias={defaultCantidadDias}
            />
          </div>
          <DataTable
            value={customers}
            responsiveLayout="stack"
            dataKey="id"
            stripedRows
            rows={5}
            loading={loading}
            emptyMessage="Sin resultados"
            footer={footerPage}
          >
            <Column
              bodyClassName={"centerRowData"}
              alignHeader="left"
              field="voicebot"
              header="Voicebot"
              body={surveyBodyTemplate}
            ></Column>
            <Column
              bodyClassName={"centerRowData"}
              alignHeader="left"
              field="nombre"
              header="Nombre"
              body={nameBodyTemplate}
            ></Column>

            <Column
              bodyClassName={"centerRowData"}
              alignHeader="center"
              field="fecha"
              header="Fecha"
            ></Column>

            <Column
              bodyClassName={"centerRowData"}
              body={(rowData) => <Amount campaignId={rowData._id} />}
              header="Cantidad de Registros"
              alignHeader="center"
            />

            {showDuration && (
              <Column
                bodyClassName={"centerRowData"}
                body={(rowData) => <Duration campaignId={rowData._id} />}
                header="Tiempo total"
                alignHeader="center"
              />
            )}

            {showDetalle && (
              <Column
                bodyClassName={"centerRowData"}
                header="Ver detalle"
                alignHeader="center"
                body={actionBodyTemplate}
                exportable={false}
              ></Column>
            )}
          </DataTable>
        </div>
      )}
    </>
  );
}
