import React from "react";
import CampaingNow from "./CampaignNow";
import CampaingPast from "./CampaingPast";

export const HomeRoot = () => {
  return (
    <div className="grid">
      <div className="col-12 xl:col-12">
        <CampaingNow />
      </div>
      <div className="col-12 xl:col-12">
        <CampaingPast />
      </div>
    </div>
  );
};
